import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  getTransactionDetails,
  getLoyaltyAllocationCampaignDetails,
} from 'api/transactions'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import { isNull, isUndefined } from 'lodash'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import {
  Transaction,
  TransactionCampaign,
  TransactionCustom,
  TransactionProduct,
  TransactionTender,
  TransactionVoucher,
} from 'types/transactions'

import Currency from 'components/ui/Currency'
import NoResults from 'components/NoResults'

type RowProps = {
  transaction: Transaction
}

const TransactionItem = ({
  transaction,
}: PropsWithChildren<RowProps>): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { traderId: memberId } = useParams()

  const [transactionFetchLimitError, setTransactionFetchLimitError] =
    useState(false)

  const toggleExpansion = useCallback(() => {
    if (isExpanded) setIsExpanded(false)
    else setIsExpanded(true)
  }, [isExpanded])

  const parseTimeZero = (t: number): string => (t < 10 ? `0${t}` : `${t}`)

  const parseTime = (dateString: string, type: 'date' | 'time'): string => {
    const date = new Date(dateString)
    if (type === 'date')
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    // return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED)
    else
      return `${parseTimeZero(date.getHours())}:${parseTimeZero(
        date.getMinutes()
      )}:${parseTimeZero(date.getSeconds())}`
    // return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE)
  }

  const campaignColumns = [
    'Campaign Name',
    'Campaign Id',
    'CR Value',
    'Loyalty Value',
    'Purchase Value',
  ]

  const productColumns = [
    'sku',
    'product id',
    'quantity',
    'product name',
    'product value',
  ]

  const tenderColumns = [
    'Identifier',
    'Tender Type',
    'Tender Value',
    'Voucher Name',
  ]

  const customColumns = ['field', 'value']

  const { data: transactionDetails, isValidating } = useSWR(
    ['/transactions/details', memberId, transaction.transactionId],
    (_, traderId, transactionId) =>
      getTransactionDetails(traderId, transactionId),
    {
      onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount && retryCount >= 5) {
          if (transactionDetails) setTransactionFetchLimitError(true)
          return
        }
        // Retry after 3 seconds.
        setTimeout(
          () => revalidate({ retryCount: (retryCount as number) + 1 }),
          3000
        )
      },
    }
  )

  useEffect(() => {
    isValidating && setTransactionFetchLimitError(false)
  }, [isValidating])

  const {
    data: allocationTransactionDetails,
    isValidating: isAllocationValidating,
  } = useSWR(
    ['/transactions/allocation_trans', memberId, transaction.transactionId],
    (_, transactionId) => getLoyaltyAllocationCampaignDetails(transactionId),
    {
      onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount && retryCount >= 5) {
          if (allocationTransactionDetails) setTransactionFetchLimitError(true)
          return
        }
        // Retry after 3 seconds.
        setTimeout(
          () => revalidate({ retryCount: (retryCount as number) + 1 }),
          3000
        )
      },
    }
  )

  useEffect(() => {
    isAllocationValidating && setTransactionFetchLimitError(false)
  }, [isAllocationValidating])

  const customData = transactionDetails
    ? getCustomData(
        isNull(transactionDetails.custom)
          ? undefined
          : transactionDetails.custom
      )
    : undefined

  // Converts object to array of strings for table mapping.
  function getCustomData(
    custom: TransactionCustom | undefined
  ): string[] | undefined {
    if (isUndefined(custom)) {
      return undefined
    } else {
      return Object.keys(custom).map(
        (key: string | null) =>
          transactionDetails.custom[key === null ? '' : key]
      )
    }
  }

  const getCount = (data: string[] | undefined): number | undefined =>
    isUndefined(data) ? 0 : data.length

  return (
    <>
      <tr
        key={transaction.transactionId}
        className="py-2 text-black bg-white border-b-2 cursor-pointer hover:bg-gray-600"
        onClick={() => toggleExpansion()}
      >
        {/* <td className="py-2 pl-6">
          {transaction.countryCode && (
            <img
              // src={`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${transaction.countryCode?.toLowerCase()}.svg`}
              // src={`https://www.countryflagicons.com/FLAT/64/${transaction.countryCode?.toUpperCase()}.png`}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${transaction.countryCode?.toUpperCase()}.svg`}
              alt={transaction.countryCode}
              className="w-8 bg-cover"
            />
          )}
        </td> */}
        <td className="py-2 pl-6  overflow-hidden overflow-ellipsis whitespace-nowrap">
          <p className="tracking-wider">
            {transaction.transactionDate
              ? parseTime(transaction.transactionDate, 'date')
              : '---'}
          </p>
          <p className="tracking-wider">
            {transaction.transactionDate
              ? parseTime(transaction.transactionDate, 'time')
              : '---'}
          </p>
        </td>
        <td className="py-2 pl-6  overflow-hidden overflow-ellipsis whitespace-nowrap">
          <p className="tracking-wider">
            {transaction.processedDate
              ? parseTime(transaction.processedDate, 'date')
              : '---'}
          </p>
          <p className="tracking-wider">
            {transaction.processedDate
              ? parseTime(transaction.processedDate, 'time')
              : '---'}
          </p>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.transactionId}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">
            {transaction.externalTransactionId}
          </span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.ident}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.storeName}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{transaction.transactionType}</span>
        </td>
        <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span className="tracking-wider">{memberId}</span>
        </td>
        <td className="py-2 pl-6">
          <p className="font-bold">
            {transaction.creditValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            <Currency countryCode={transaction.countryCode} />
          </p>
        </td>
        <td className="py-2 pl-6">
          <p className="font-bold">
            {transaction.loyaltyValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </p>
        </td>
        <td className="py-2 pl-6">
          <p className="font-bold">
            {transaction.purchaseValue.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            <Currency countryCode={transaction.countryCode} />
          </p>
        </td>
        <td className="py-2 text-center">
          {isExpanded ? <Icon icon={faSortUp} /> : <Icon icon={faSortDown} />}
        </td>
      </tr>
      {isExpanded && (
        <tr className="bg-gray-150">
          <td colSpan={12}>
            <div className="p-6 m-6 bg-white rounded-sm">
              <>
                <div className="flex flex-row justify-between align-between">
                  <h3 className="mb-3">
                    {transaction.transactionType} from {transaction.storeName}
                  </h3>
                  <div className="flex flex-row">
                    <div className="mr-4">
                      <p className="font-bold">
                        {transaction.creditValue.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Currency countryCode={transaction.countryCode} />
                      </p>

                      <p className="text-gray-500">
                        <span className="tracking-wider font-light text-xs">
                          CR Value
                        </span>
                      </p>
                    </div>
                    <div className="mr-4">
                      <p className="font-bold">
                        {transaction.loyaltyValue.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        {/* <Currency countryCode={transaction.countryCode} /> */}
                      </p>
                      <p className="text-gray-500">
                        <span className="tracking-wider font-light text-xs">
                          Loyalty Value
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="font-bold">
                        {transaction.purchaseValue.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Currency countryCode={transaction.countryCode} />
                      </p>
                      <p className="text-gray-500">
                        <span className="tracking-wider font-light text-xs">
                          Purchase Value
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {transactionFetchLimitError ? (
                  <h2>
                    Failed to load data. Please refresh the page and try again,
                    or contact your system administrator.
                  </h2>
                ) : isUndefined(transactionDetails) ? (
                  <div className="flex justify-center w-screen m-auto my-4 place-self-center">
                    <Spinner />
                  </div>
                ) : (
                  <Tabs>
                    <Tab
                      title="Campaign"
                      count={getCount(
                        transaction.transactionType === 'Loyalty Allocation'
                          ? allocationTransactionDetails.campaignDetails
                          : transactionDetails.campaigns
                      )}
                    >
                      <div className="mt-6 bg-white">
                        {/* Store the campaign data in a variable for easier reuse */}
                        {(() => {
                          const isLoyaltyAllocation =
                            transaction.transactionType === 'Loyalty Allocation'
                          const campaigns = isLoyaltyAllocation
                            ? allocationTransactionDetails.campaignDetails
                            : transactionDetails.campaigns
                          const campaignDetails = isLoyaltyAllocation
                            ? allocationTransactionDetails.campaignDetails
                            : transactionDetails.campaigns

                          return isNull(campaignDetails) ||
                            !campaignDetails.length ? (
                            <NoResults noImage />
                          ) : (
                            <Table
                              columns={campaignColumns}
                              data={campaignDetails}
                              pageCount={10}
                              currentPage={1}
                              onPageChange={() => null}
                              paginate={false}
                            >
                              {campaigns.map(
                                (item: TransactionCampaign, index: number) => {
                                  return (
                                    <tr
                                      key={'txn-campaign-' + index}
                                      className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
                                      // redirect to member profile
                                      onClick={() => null}
                                    >
                                      <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                        <p>{item.campaignName}</p>
                                      </td>
                                      <td className="py-2 pl-6">
                                        <p>{item.campaignId}</p>
                                      </td>
                                      {/* Conditionally render values based on their existence */}
                                      {item.creditValue !== undefined && (
                                        <td className="py-2 pl-6">
                                          <p>
                                            {item.creditValue}
                                            <Currency
                                              countryCode={
                                                transaction.countryCode
                                              }
                                            />
                                          </p>
                                        </td>
                                      )}
                                      {item.loyaltyValue !== undefined && (
                                        <td className="py-2 pl-6">
                                          <p>{item.loyaltyValue}</p>
                                        </td>
                                      )}
                                      {item.purchaseValue !== undefined && (
                                        <td className="py-2 pl-6">
                                          <p>
                                            {item.purchaseValue}
                                            <Currency
                                              countryCode={
                                                transaction.countryCode
                                              }
                                            />
                                          </p>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                }
                              )}
                            </Table>
                          )
                        })()}
                      </div>
                    </Tab>
                    <Tab
                      title="Product Details"
                      count={getCount(
                        transactionDetails.products
                          ? transactionDetails.products
                          : undefined
                      )}
                    >
                      <div className="mt-6 bg-white">
                        {isNull(transactionDetails.products) ||
                        !transactionDetails.products.length ? (
                          <NoResults noImage />
                        ) : (
                          <Table
                            columns={productColumns}
                            data={transactionDetails.products}
                            pageCount={10}
                            currentPage={1}
                            onPageChange={() => null}
                            paginate={false}
                          >
                            {transactionDetails.products.map(
                              (item: TransactionProduct, index: number) => (
                                <tr
                                  key={'txn-product-' + index}
                                  className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
                                  // redirect to member profile
                                  onClick={() => null}
                                >
                                  <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    <p>{item.sku}</p>
                                  </td>
                                  <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    <p>{item.productid}</p>
                                  </td>
                                  <td className="py-2 pl-6">
                                    <p>{item.quantity}</p>
                                  </td>
                                  <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    <p>{item.productName}</p>
                                  </td>
                                  <td className="py-2 pl-6">
                                    <p>
                                      {item.productValue.toLocaleString(
                                        undefined,
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                      <Currency
                                        countryCode={transaction.countryCode}
                                      />
                                    </p>
                                  </td>
                                </tr>
                              )
                            )}
                          </Table>
                        )}
                      </div>
                    </Tab>
                    <Tab
                      title="Tender Details"
                      count={getCount(
                        transactionDetails.tenders
                          ? transactionDetails.tenders
                          : undefined
                      )}
                    >
                      <div className="mt-6 bg-white">
                        {isNull(transactionDetails.tenders) ||
                        !transactionDetails.tenders.length ? (
                          <NoResults noImage />
                        ) : (
                          <Table
                            columns={tenderColumns}
                            data={transactionDetails.tenders}
                            pageCount={10}
                            currentPage={1}
                            onPageChange={() => null}
                            paginate={false}
                          >
                            {transactionDetails.tenders.map(
                              (tender: TransactionTender, index: number) => {
                                // Find matching voucher for the tender if tenderTypeId is 21
                                const voucher =
                                  tender.tenderTypeId === '21'
                                    ? transactionDetails.vouchers.find(
                                        (voucher: TransactionVoucher) =>
                                          voucher.voucherCode ===
                                          tender.identifier
                                      )
                                    : null

                                return (
                                  <tr
                                    key={'txn-tender-' + index}
                                    className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
                                    // redirect to member profile
                                    onClick={() => null}
                                  >
                                    <td className="py-2 pl-6">
                                      <p>{tender.identifier}</p>
                                    </td>
                                    <td className="py-2 pl-6 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                      <p>{tender.name}</p>
                                    </td>
                                    <td className="py-2 pl-6">
                                      <p>
                                        {tender.tenderValue.toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          }
                                        )}
                                        <Currency
                                          countryCode={transaction.countryCode}
                                        />
                                      </p>
                                    </td>
                                    <td className="py-2 pl-6">
                                      {/* Display voucher name if exists, otherwise empty string */}
                                      <p>{voucher ? voucher.name : ''}</p>
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </Table>
                        )}
                      </div>
                    </Tab>
                    <Tab
                      title="Custom"
                      count={getCount(customData ? customData : undefined)}
                    >
                      <div className="mt-6 bg-white">
                        {isUndefined(customData) || !customData.length ? (
                          <NoResults noImage />
                        ) : (
                          <Table
                            columns={customColumns}
                            data={customData}
                            pageCount={10}
                            currentPage={1}
                            onPageChange={() => null}
                            paginate={false}
                          >
                            {customData
                              ? customData.map(
                                  (item: string, index: number) => (
                                    <tr
                                      key={'txn-custom-' + index}
                                      className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
                                      // redirect to member profile
                                      onClick={() => null}
                                    >
                                      <td className="py-2 pl-6">
                                        <p>Custom{index + 1}</p>
                                      </td>
                                      <td className="py-2 pl-6">
                                        <p>{item ? item : '--'}</p>
                                      </td>
                                    </tr>
                                  )
                                )
                              : null}
                          </Table>
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                )}
              </>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default TransactionItem
